import './assets/main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import router from './router'
import { useDark, useStorage } from '@vueuse/core'
import * as Sentry from '@sentry/vue'
import mitt from 'mitt'
import getEnv from '@/utils/env.js'
import { oidc, oidcUser } from '@/utils/oidc.js'
import { useUserLocale } from '@/utils/userLocale.js'
import countries from 'i18n-iso-countries'
import english from 'i18n-iso-countries/langs/en.json'
import german from 'i18n-iso-countries/langs/de.json'

Sentry.init({
  dsn: 'https://1e6bf2b874b6311c7b94390a779921f7@o4504999945240576.ingest.us.sentry.io/4506868672692224',
  enabled: !getEnv('DEV'),
  environment: getEnv('VITE_SENTRY_ENV'),
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    })
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', 'epliance.cloud'],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

Sentry.setUser({
  email: oidcUser.profile.email,
  id: oidcUser.profile.sub
})

const app = createApp(App)

const isDark = useDark({
  storageKey: 'color-scheme'
})

const locale = useStorage('locale', useUserLocale())
const i18n = createI18n({
  locale: locale.value,
  fallbackLocale: 'en',
  legacy: false
})

i18n.global.setLocaleMessage('de', await import('./locales/de.json'))
i18n.global.setLocaleMessage('en', await import('./locales/en.json'))

countries.registerLocale(english)
countries.registerLocale(german)

countries.getAll = () => {
  let items = []

  Object.keys(countries.getAlpha2Codes()).forEach((item) => {
    items.push({
      name: countries.getName(item, locale.value),
      alpha2: item,
      alpha3: countries.alpha2ToAlpha3(item),
      numeric: countries.alpha2ToNumeric(item)
    })
  })

  return items
}

app.use(i18n)
app.use(createPinia())
app.use(router)

app.provide('isDark', isDark)
app.provide('locale', locale)
app.provide('countries', countries)
app.provide('oidc', oidc)
app.provide('user', oidcUser.profile)
app.provide('isSuperAdmin', oidcUser.profile.groups.includes('ROLE_BATMAN'))
app.provide('isDeveloper', oidcUser.profile.groups.includes('DEVELOPER'))
app.provide('isSupervisor', oidcUser.profile.groups.includes('ROLE_SUPERVISOR'))
app.provide('downloadAllowed', oidcUser.profile.groups.includes('ROLE_CAN_DOWNLOAD'))
app.provide('canSeeSettings', oidcUser.profile.groups.includes('ROLE_SETTINGS'))
app.provide('eventBus', mitt())

async function enableMocking() {
  const enableMocking = useStorage('mocking', false)

  if (!getEnv('DEV') || !enableMocking.value) {
    return
  }

  const { worker } = await import('./mocks/browser')

  // `worker.start()` returns a Promise that resolves
  // once the Service Worker is up and ready to intercept requests.
  return worker.start()
}

enableMocking().then(() => {
  app.mount('#app')
})
